<template>
  <div class="privacy container">
    <div>
      <h1>Privacy Policy</h1>
      <p>Last updated: 07.12.2023</p>
      <ol>
        <li>
          Your privacy is important to us. It is TrendsOn.social's policy to
          respect your privacy regarding any information we may collect while
          operating our website. Accordingly, we have developed a Privacy Policy
          in order for you to understand how we collect, use, communicate,
          disclose, and otherwise make use of personal information. We have
          outlined our privacy policy below.
        </li>
        <li>
          Before or at the time of collecting personal information, we will
          identify the purposes for which information is being collected.
        </li>
        <li>
          We will collect and use personal information solely for fulfilling
          those purposes specified by us and for other ancillary purposes,
          unless we obtain the consent of the individual concerned or as
          required by law.
        </li>
        <li>
          Personal data should be relevant to the purposes for which it is to be
          used, and, to the extent necessary for those purposes, should be
          accurate, complete, and up-to-date.
        </li>
        <li>
          We will protect personal information by using reasonable security
          safeguards against loss or theft, as well as unauthorized access,
          disclosure, copying, use or modification.
        </li>
        <li>
          We will make readily available to customers information about our
          policies and practices relating to the management of personal
          information.
        </li>
        <li>
          We will only retain personal information for as long as necessary for
          the fulfillment of those purposes.
        </li>
        <li>
          TrendsOn.social may change this privacy policy from time to time at
          TrendsOn.social's sole discretion.
        </li>
        <li>
          By using our website, you hereby consent to our Terms and Conditions
          and agree to its terms.
        </li>
      </ol>
      <p></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
}
</script>

<style lang="scss">
.privacy {
  padding: 50px 0;
  text-align: left;
  min-height: 74vh;
  li {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
</style>
