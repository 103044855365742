import { createRouter, createWebHistory } from 'vue-router';
import MainPage from '@/views/MainPage.vue'
import TermsAndConditions from '@/views/TermsAndConditions.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'

const routes = [
  {
    path: '/',
    name: 'Main',
    component: MainPage
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router
