<template>
  <div class="terms container">
    <h1>Terms and Conditions</h1>

    <p>Last updated: 07.12.2023</p>

    <p>
      Welcome to TrendsOn.social! These Terms and Conditions outline the rules
      and regulations for the use of TrendsOn.social's Website, located at
      www.trendson.social.
    </p>
    <p>
      By accessing this website, we assume you accept these terms and
      conditions. Do not continue to use TrendsOn.social if you do not agree to
      take all of the terms and conditions stated on this page. Copyright Notice
    </p>
    <p>
      Copyright © 2023 TrendsOn.social. All rights reserved. Unless otherwise
      stated, TrendsOn.social and/or its licensors own the intellectual property
      rights for all material on TrendsOn.social. All intellectual property
      rights are reserved. You may access this from TrendsOn.social for your own
      personal use subjected to restrictions set in these terms and conditions.
    </p>
    <p>License to Use Website</p>
    <p>You must not:</p>
    <ul>
      <li>
        Republish material from TrendsOn.social Sell, rent, or sub-license
        material from TrendsOn.social Reproduce, duplicate or copy material from
        TrendsOn.social Redistribute content from TrendsOn.social
      </li>
      <li>This Agreement shall begin on the date hereof.</li>
      <li>
        Parts of this website offer an opportunity for users to post and
        exchange opinions and information in certain areas of the website.
        TrendsOn.social does not filter, edit, publish or review Comments prior
        to their presence on the website. Comments do not reflect the views and
        opinions of TrendsOn.social, its agents, and/or affiliates. Comments
        reflect the views and opinions of the person who post their views and
        opinions. To the extent permitted by applicable laws, TrendsOn.social
        shall not be liable for the Comments or for any liability, damages, or
        expenses caused and/or suffered as a result of any use of and/or posting
        of and/or appearance of the Comments on this website.
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'TermsAndConditions',
}
</script>
