<template>
  <div>
    <section id="about">
      <div class="container">
        <div class="about--wrapper">
          <h1>Welcome to TrendsOn.SOCIAL</h1>
          <h3>Discover What's Trending in Your World</h3>
          <div>
            <p>
              In the fast-paced world of social media, staying ahead of the
              curve is key. TrendsOn.SOCIAL is your gateway to uncovering the
              most popular and trending topics across major social media
              platforms - Facebook, TikTok, Twitter, and Instagram.
            </p>
            <p>
              We're here to bridge the gap between you and the pulse of social
              conversations, all tailored to your specific location.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section id="benefits">
      <div class="container">
        <h2>Benefits</h2>
        <div class="benefits--wrapper">
          <div class="benefit--single">
            <p>Local Relevance:</p>
            <p>
              Get insights that are geographically relevant to you. Whether
              you're a business, influencer, or curious individual,
              understanding local trends is crucial.
            </p>
          </div>
          <div class="benefit--single">
            <p>Cross-Platform Analysis:</p>
            <p>
              No need to toggle between apps. We bring together the trending
              content from multiple platforms in one place for your convenience.
            </p>
          </div>
          <div class="benefit--single">
            <p>Time-Saving:</p>
            <p>
              We cut through the noise to bring you what's genuinely trending,
              saving you hours of browsing.
            </p>
          </div>
          <div class="benefit--single">
            <p>Marketing Insights:</p>
            <p>
              For businesses and marketers, our platform offers invaluable
              insights into what's capturing the interest of your local
              audience, helping you tailor your strategies effectively.
            </p>
          </div>
          <div class="benefit--single">
            <p>User-Friendly Interface:</p>
            <p>
              Our platform is designed with simplicity and ease of use in mind,
              ensuring a seamless experience for all users.
            </p>
          </div>
          <div class="benefit--single">
            <p>Regular Updates:</p>
            <p>
              Stay ahead with regular updates, so you're always in tune with the
              latest trends.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section id="info">
      <div class="container">
        <h2>Join the Trend Revolution</h2>
        <p>
          At TrendsOn.SOCIAL, we're more than just a trend analysis tool; we're
          your partner in navigating the ever-changing landscape of social
          media. Stay informed, stay relevant, and make informed decisions based
          on real-time data from the world around you.
        </p>
      </div>
    </section>
  </div>
</template>
